<template>
  <v-app id="app">
    <div id="2fa-captcha"></div>
    <v-navigation-drawer
      width='290'
      v-model="drawer"
      v-if='user.loggedIn'
      app
    >
      <v-sheet class="pa-4">
        <v-img
          contain
          aspect-ratio='1.7778'
          src='./assets/opd_logo.png'
          max-height='120'
        >
        </v-img>

        <div>Welcome, {{user? user.data.email : ""}}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>

        <div
          :key="item.title"
          v-for="item in items"
        >
          <v-list-group
            v-if='item.items'
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              link
              v-for="child in item.items"
              :key="child.title"
              :to='child.to'
              :disabled='child.disabled'
            >

              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item to='#' v-else-if="item?.title === 'Sign Out'">
       
       <v-list-item-icon>
         <v-icon>{{item.icon}}</v-icon>
       </v-list-item-icon>
           <v-list-item-title @click='signOut' v-text="item.title"></v-list-item-title>

       </v-list-item>
          <v-list-item
            v-else
            :to='item.to'
            :disabled='!item.active'
          >
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </div>

       

       
      </v-list>

    </v-navigation-drawer>

    <v-main class="main-content">
    <v-app-bar v-if="user.loggedIn" app elevate-on-scroll color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-h6 font-weight-bold">
        OPD Apps Admin Portal
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="user.loggedIn"
        @click="darkModeOn"
        icon
        class="mr-2"
      >
        <v-icon>mdi-lightbulb-night</v-icon>
      </v-btn>

      <v-btn icon class="mr-2">
        <v-icon>mdi-bell</v-icon>
      </v-btn>

      <v-avatar color="accent" size="40">
        <span class="white--text text-h6">{{ userInitials }}</span>
      </v-avatar>
    </v-app-bar>

    <v-container fluid class="pa-6">
      <router-view />
    </v-container>
  </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { mapFields } from "vuex-map-fields";
export default {
  methods: {
    signOut(){
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
        });
    },
    darkModeOn() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    signout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
        });
    },
  },
  mounted() {},
  computed: {
    userInitials() {
      return this.user.data.email.split(' ').map(n => n[0]).join('').toUpperCase();
    },
    ...mapFields(["user"]),
  },
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    items: [
      {
        title: "Home",
        icon: "mdi-home",
        to: "/",
        active: true,
        requireAdmin: false,
      },
      {
        title: "Report",
        icon: "mdi-chart-bar",
        to: "#",
        active: false,
        requireAdmin: false,
      },
      {
        title: "Notifications",
        icon: "mdi-checkbox-blank-badge-outline",
        to: "#",
        active: false,
        requireAdmin: false,
      },
      // {
      //   icon: "mdi-application-cog-outline",
      //   title: "App Setting",
      //   active: false,
      //   requireAdmin: false,
      //   items: [
      //     {
      //       title: "Contract App",
      //       to: "/dp1",
      //       requireAdmin: false,
      //     },
      //     {
      //       title: "TimeSheet App",
      //       to: "/dp2",
      //       requireAdmin: false,
      //     },

        
      //   ],
      // },
      {
        icon: "mdi-earth",
        title: "System Setting",
        active: false,
        requireAdmin: false,
        items: [
        { title: "Create Org", to: "/org-settings", requireAdmin: false },
        { title: "List Org", to: "/orgs", requireAdmin: false },
         
          {
            title: "Create User",
            to: "/newUser",
            requireAdmin: false,
          },
          { title: "Manage Users", to: "/users", requireAdmin: false },
          {
            title: "Logs",
            to: "/logs",
            requireAdmin: false,
          },


        ],
      },

      // {
      //   icon: "mdi-security",
      //   title: "Admin Only",
      //   active: false,
      //   requireAdmin: false,
      //   items: [
      //     { title: "Admin Panel", to: "/adminPanel", requireAdmin: false },

      //     {
      //       title: "Activity Log",
      //       to: "/activityLog",
      //       requireAdmin: false,
      //     },
      //   ],
      // },

      {
        title: "Sign Out",
        icon: "mdi-monitor",
        to: "/signout",
        active: true,
        requireAdmin: false,
      },
    
    ],
  }),
};
</script>


<style scoped>
.main-content {
  background-color: #f8f9fa;
}
</style>
