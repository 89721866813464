
import axios from 'axios';


const deleteOrg = async (id) => {

    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/deleteOrg/"+id,{
       name : name,
      })
      return result.data;
 
 }


 const disableOrg = async (id,endDate) => {

   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/disableOrg/"+id,{
      endDate
   })
     return result.data;

}


 
 const getAllOrgs = async () => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/getOrgs")
      return result.data;
 }
 const createNewOrg = async (name,caseloadCount,status,effectiveDate) => {
 
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createOrg",{
       name,
       caseloadCount,
       status,
       effectiveDate
      })
      return result.data;
 
 }


 export {deleteOrg,getAllOrgs,createNewOrg,disableOrg}