import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import ActivityLog from "../views/ActivityLog.vue"
import Login from "../views/Login.vue"
import firebase from "firebase/compat/app";
import store from '../store'

import contractsByContractor from '../views/contractsByContractor'

import "firebase/auth";

import AdminPanel  from "../views/AdminPanel.vue"
import Users from "../views/Users.vue"
import Orgs from "../views/Orgs.vue"
import newUser from  "../views/NewUser.vue"
import Accounts from "../views/Accounts.vue"
import Logs from "../views/Logs.vue"
import OrgSettings from "../views/OrgSettings.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
		path: "/login",
		name: "Login",
		component: Login,
	},
  {
    path: '/org-settings',
    name: 'Organization Settings',
    component: OrgSettings,
    meta: { requiresAuth: true },
  },
  
  {
    path: '/activityLog',
    name: 'Activity Log',
    component: ActivityLog,
    meta: { requiresAuth: true },
  },
  
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: { requiresAuth: true },
  },
  
  
  {
    path: '/adminPanel',
    name: 'Admin Panel',
    component: AdminPanel,
    meta: { requiresAuth: true },
  },

  {
    path: '/users',
    name: 'Manage Users',
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: '/orgs',
    name: 'Manage Orgs',
    component: Orgs,
    meta: { requiresAuth: true },
  },
  {
    path: '/Logs',
    name: 'All Logs',
    component: Logs,
    meta: { requiresAuth: true },
  },

  {
    path: '/newUser',
    name: 'Create New User',
    component: newUser,
    meta: { requiresAuth: true },
  },
  
  {
    path: '/contractsByContractor/:id',
    name: 'All Contracts By Contrator SWV',
    component: contractsByContractor,
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  firebase.auth().onAuthStateChanged(async (user) => {
   if(to.name === "Login" && user){
    next({
      path: "/",
    });
   }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      
      if (user) {
         let { claims } = await firebase.auth().currentUser.getIdTokenResult();
         console.log(claims);
         if(!claims.appLevel.includes(0)){
          firebase
          .auth()
          .signOut()
          .then(() => {
            location.reload();
          });
         }
        store.commit("SET_USER", user);
        next();
      } else {
        next({
          path: "/login",
        });
      }
    } else {
      next(); // make sure to always call next()!
    }
    
  });


});


export default router
