<template>
  <v-card class="user-creation-card" elevation="3">
    <v-card-title class="headline primary white--text">
      {{editMode ? "Edit" : "Create New" }} User
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newItem.displayName"
                label="Display Name"
                :rules="displayNameRules"
                required
                outlined
                dense
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newItem.email"
                label="Email"
                :rules="emailRules"
                required
                outlined
                dense
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="newItem.FirstName"
        label="First Name"
      
        outlined
        dense
        prepend-inner-icon="mdi-account"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="newItem.LastName"
        label="Last Name"
      
        outlined
        dense
        prepend-inner-icon="mdi-account"
      ></v-text-field>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="newItem.Address1"
        label="Address 1"
      
        outlined
        dense
        prepend-inner-icon="mdi-map-marker"
      ></v-text-field>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="newItem.Address2"
        label="Address 2"
        outlined
        dense
        prepend-inner-icon="mdi-map-marker"
      ></v-text-field>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="newItem.Address3"
        label="Address 3"
        outlined
        dense
        prepend-inner-icon="mdi-map-marker"
      ></v-text-field>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col cols="12" md="4">
      <v-select
        v-model="newItem.State"
        :items="usStates"
        label="State"
     
        outlined
        dense
        prepend-inner-icon="mdi-map"
      ></v-select>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="newItem.City"
        label="City"
      
        outlined
        dense
        prepend-inner-icon="mdi-city"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="newItem.ZipCode"
        label="ZIP Code"
      
        outlined
        dense
        prepend-inner-icon="mdi-map-marker-radius"
      ></v-text-field>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col cols="12" md="6">


                
                <v-select
                 item-text="name"
                  item-value="id"
        v-model="newItem.OfficeLawFirm"
        label="Office/Law Firm"
      :items='orgs'
        outlined
        dense
        prepend-inner-icon="mdi-office-building"
      > </v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="newItem.WSBA"
        label="WSBA"


        outlined
        dense
        prepend-inner-icon="mdi-card-account-details"
      ></v-text-field>
    </v-col>
  </v-row>

          <v-divider class="my-4"></v-divider>

          <h3 class="subtitle-1 font-weight-bold mb-2">Apps Access Level</h3>
          <v-row v-if="newItem.customClaims">
            <v-col cols="12" sm="6" md="4" v-for="(app, index) in apps" :key="index">
              <v-checkbox
                v-model="newItem.customClaims.appLevel"
                :label="app.name"
                :value="index"
                hide-details
                class="mb-2"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>

          <h3 class="subtitle-1 font-weight-bold mb-2">Role Configuration</h3>
          <v-row v-if="newItem.customClaims?.roles">
            <v-col cols="12" v-for="(r, i) in newItem.customClaims.roles" :key="i">
              <v-card outlined class="pa-3 mb-3">
                <p>
                  {{r.name }}
                </p>
                <v-row>
                  
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="r.appId"
                      label="App ID"
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      :items='["Super_Admin", "Admin", "Program_Admin", "User"]'
                      v-model="r.role"
                      label="Role"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">

                    <v-select
                      :items='["Attorney", "Social Worker","Other"]'
                      v-model="r.title"
                      label="Title"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                    disabled
                 item-text="name"
                  item-value="id"
        v-model="newItem.OfficeLawFirm"
        label="Office/Law Firm"
      :items='orgs'
        outlined
        dense
        prepend-inner-icon="mdi-office-building"
      > </v-select>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="create"
        x-large
        :disabled="!valid"
        class="px-8"
      >
      {{editMode ? "Edit" : "Create New" }} User
      </v-btn>

      <v-btn
        color="warning"
        @click="disableUser"
        x-large
        :disabled="!valid"
        class="px-8"
      >
      Disable User
      </v-btn>

      <v-btn
        x-large
        class="px-8"
      >
      Cancel
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
  
  <script>
  import { createUser, editUser, getAllOrg, disableUserAPICall} from '../dataservices/adminDataService';
  
  export default {
    props : {
      editMode : Boolean,
      userObj : Object,
      metaData : Object
    },
    watch: {
      userObj: {
        handler(newValue) {
          if(this.editMode){
            this.newItem = {...newValue, ...this.metaData}

      }
          
      },
      deep: true 
      },
  },
    async mounted() {
      this.orgs = await getAllOrg();
      console.log(this.orgs)
      if(this.userObj && this.editMode){
        this.newItem = this.userObj;
        this.newItem = {...this.newItem, ...this.metaData}

      }
    },
    data() {
      return {
        orgs : [],
        apps: [
      { name: 'Admin App', value: 0 },
      { name: 'OPDCS', value: 1 },
      { name: 'CATTS', value: 2 },
      { name: 'SPAR', value: 3 },
      { name: 'NGRI', value: 4 },
      { name: '71.09', value: 5 }
    ],
    usStates: [
      'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
      'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
      'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
      'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
      'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ],
        valid : true,
        newItem: {
          email: '',
          displayName: '',
          FirstName: '',
      LastName: '',
      Address1: '',
      Address2: '',
      Address3: '',
      State: '',
      City: '',
      ZipCode: '',
      OfficeLawFirm: '',
      WSBA: '',
          customClaims: {
            appLevel: [],
            roles: [
              {
                name : "Admin App",
                role: 'User',
                title: null,
                org: null,
                appId: 0,
              },
              {
                name : "OPDCS",
                role: 'User',
                title: null,
                org: null,
                appId: 1,
              },
              {
                name : "CATTS",
                role: 'User',
                title: null,
                org: null,
                appId: 2,
              },
              {
                name : "SPAR",
                role: 'User',
                title: null,
                org: null,
                appId: 3,
              },
              {
                name : "NGRI",
                role: 'User',
                title: null,
                org: null,
                appId: 4,
              },
            ],
          },
        },
        nameRules: [
      v => !!v || 'Name is required',
    ],
    addressRules: [
      v => !!v || 'Address is required',
    ],
    stateRules: [
      v => !!v || 'State is required',
    ],
    cityRules: [
      v => !!v || 'City is required',
    ],
    zipRules: [
      v => !!v || 'ZIP Code is required',
      v => /^\d{5}(-\d{4})?$/.test(v) || 'ZIP Code must be valid',
    ],
    officeLawFirmRules: [
      v => !!v || 'Office/Law Firm is required',
    ],
    wsbaRules: [
      v => !!v || 'WSBA is required',
    ],
        displayNameRules: [
      v => !!v || 'Display name is required',
      v => v.length <= 50 || 'Display name must be less than 50 characters'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
      };
    },
    computed: {
   
    },
    methods: {
      async disableUser(){
        await disableUserAPICall(this.newItem.uid)
      },
      async create() {
        if (this.$refs.form.validate()) {
          this.editMode ?  
          await editUser(this.newItem) : 
          await createUser({
            email: this.newItem.email,
            name: this.newItem.displayName,
            appLevel: this.newItem.customClaims.appLevel,
            role: this.newItem.customClaims.roles,
            FirstName: this.newItem.FirstName,
            LastName: this.newItem.LastName,
            Address1: this.newItem.Address1,
            Address2: this.newItem.Address2,
            Address3: this.newItem.Address3,
            City: this.newItem.City,
            State: this.newItem.State,
            ZipCode: this.newItem.ZipCode,
          OfficeLawFirm: this.newItem.OfficeLawFirm,
          WSBA: this.newItem.WSBA,
          });
          location.reload();
        }
      },
    },
  };
  </script>
  

  <style scoped>
.user-creation-card {
  max-width: 900px;
  margin: 0 auto;
}

.headline {
  font-size: 1.5rem !important;
}

.subtitle-1 {
  font-size: 1.1rem !important;
}
</style>