<template>
  <v-container>
    <v-card>
      <v-card-title>
        Organization Settings
      </v-card-title>
      <v-card-text>
        <v-expansion-panels
          v-model="panel"
          multiple
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Add new Office/Law Firm
            </v-expansion-panel-header>
            <v-expansion-panel-content>
  <v-form ref='form'>
    <v-text-field
      label='Name'
      :rules="requiredRule"
      v-model='name'
    >
    </v-text-field>

    <v-checkbox
      label='STATUS'
      :rules="requiredRule"
      v-model='status'
    >
    </v-checkbox>

    <v-text-field
      label='Caseload Count'
      type='number'
      v-model='caseloadCount'
      min='0'
      max='999'
    >
    </v-text-field>

    <v-text-field v-model="effectiveDate" :rules="requiredRule" type="date" label="Date" required></v-text-field>

    <v-btn
      :loading='btnloading'
      @click='submit'
      color='primary'
    >
      Submit
    </v-btn>
  </v-form>
</v-expansion-panel-content>
          </v-expansion-panel>


  
         
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import { createNewOrg,deleteOrg,getAllOrg } from "../dataservices/adminDataService";
export default {
  async mounted() {
    this.orgs = await getAllOrg();
  },
  methods: {
    async deleteOrgHandler(){
      this.btnloading2 = true;
      let valid = this.$refs.form2.validate();
      if (valid) {
        try {
          await deleteOrg(this.deleteOrg);
          this.btnloading2 = false;
          location.reload();
        } catch (e) {
          alert("something went wrong, please check to see if this org is assoicated with any accounts.");
          this.btnloading2 = false;
        }
      } else {
        return;
      }
    },
    async submit() {
      this.btnloading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        try {
          await createNewOrg(this.name,this.caseloadCount,this.status,this.effectiveDate);
          this.btnloading = false;
          location.reload();
        } catch (e) {
          alert("something went wrong, please check your inputs");
          this.btnloading = false;
        }
      } else {
        this.btnloading = false;
        return;
      }
    },
  },
  data() {
    return {
      btnloading2 : false,
      deleteOrg : null,
      orgs : [],
      btnloading: false,
      status: false,
    caseloadCount: 0,
      effectiveDate: new Date().toISOString().substr(0, 10), 
      requiredRule: [(v) => !!v || "This field is required"],
      name: null,
      panel: [0,1],
    };
  },
};
</script>

<style>
</style>