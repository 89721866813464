<template>
  <div class='ma-2'>
    <v-card-title>Orgs</v-card-title>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        item-key="row_id"
        :items-per-page="20"
        v-model="selected"
        :loading="loading"
        loading-text="Loading... Please wait"
        multi-sort
        :sort-by="sortby"
        :sort-desc="sortDesc"
        :headers="headers"
        :items="orgs"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn :disabled="!item?.enable" icon small @click="openDisableDialog(item)">
            <v-icon small>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Disable Organization Dialog -->
    <v-dialog v-model="disableDialog"  max-width="500px">
      <v-card>
        <v-card-title>
          Disable Organization
        </v-card-title>
        
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="disableForm.status"
                  label="Status"
                  :error-messages="statusErrors"
                  @input="clearError('status')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  value="2099-01-01"
                  v-model="disableForm.endDate"
                  label="End Effective Date"
                  type="date"
                  :error-messages="endDateErrors"
                  @input="clearError('endDate')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmDisable"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="closeDisableDialog"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import { getAllOrgs,disableOrg } from "../dataservices/orgsDataService";

export default {

  data() {
    return {
        valid: true,
    
      dialog: false,
      selected: [],
      loading: true,
      sortby: [],
      sortDesc: [],
      headers: [
        { text: "id", align: "start", sortable: true, value: "id" },
        { text: "Org Name", align: "start", sortable: true, value: "name" },
        { text: "Effective Date", align: "start", sortable: true, value: "effective_date" },
        { text: "End Effective Date", align: "start", sortable: true, value: "end_effective_date" },
        { text: "Case Load Count", align: "start", sortable: true, value: "caseload_count" },
        { text: "STATUS", align: "start", sortable: true, value: "status" },
        { text: "Enabled?", align: "start", sortable: true, value: "enable" },
        { text: "Actions", value: "actions", sortable: false },

      ],
      disableDialog: false,
      search: null,
      editedIndex: -1,
      orgs : [],
      selectedItem: null,
      disableForm: {
        status: false,
        endDate: '2099-01-01', // Set default value here
      },
      formErrors: {
        status: [],
        endDate: [],
      }
    };
  },
  async mounted() {
    this.orgs = await getAllOrgs();
    this.loading = false;
  },
  computed: {
    statusErrors() {
      return this.formErrors.status
    },
    endDateErrors() {
      return this.formErrors.endDate
    }
  },
  methods: {
    async confirmDisable() {
      if (!this.validateForm()) {
        return
      }

      try {
     
        disableOrg(this.selectedItem.id, this.disableForm.endDate);
        location.reload();
        this.closeDisableDialog()
        // Optionally refresh the data or show success message
      } catch (error) {
        console.error('Error disabling organization:', error)
        // Handle error (show error message, etc.)
      }
    },

    closeDisableDialog() {
      this.disableDialog = false
      this.selectedItem = null
      this.clearErrors()
    },

    clearError(field) {
      this.formErrors[field] = []
    },

    clearErrors() {
      this.formErrors = {
        status: [],
        endDate: []
      }
    },
    validateForm() {
      let isValid = true
      this.clearErrors()

      if (!this.disableForm.status) {
        this.formErrors.status = ['Status is required']
        isValid = false
      }

      if (!this.disableForm.endDate) {
        this.formErrors.endDate = ['End effective date is required']
        isValid = false
      }

      return isValid
    },
    openDisableDialog(item) {
      this.selectedItem = item
      this.disableDialog = true
      // Reset form
      this.disableForm = {
        status: false,
        endDate: '2099-01-01'  
      }
      this.formErrors = {
        status: [],
        endDate: []
      }
    },


  
  },
};
</script>
<style scoped>
.v-data-table ::v-deep tbody tr:hover {
  background-color: #f5f5f5;
}

.v-data-table ::v-deep .v-data-table__expanded__content {
  box-shadow: inset 0 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0 -4px 8px -4px rgba(0, 0, 0, 0.1);
}

.v-card {
  transition: all 0.3s ease-in-out;
}



.user-edit-card {
  max-width: 900px;
  margin: 0 auto;
}

.headline {
  font-size: 1.5rem !important;
}

.subtitle-1 {
  font-size: 1.1rem !important;
}
</style>